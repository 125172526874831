import React, {useRef, useState} from 'react';
import './App.css';
import '@livekit/components-styles';
import {LiveKitRoom, VideoConference,} from '@livekit/components-react';
import {RemoteParticipant, RemoteTrack, RemoteTrackPublication, Room, RoomEvent} from "livekit-client";
import {VIDEOINSPECTION_CONFIG} from "./videoinspection/configs";
import {
  AUDIO_MERGE_STATE,
  AudioMergeItem,
  blobToBase64, getErrorString,
  getMergedAudioRequest,
  isVideoInspectionMessage,
  sendPostMessageData,
  setupGui,
  VideoInspectionGeolocation,
  VideoInspectionInfoData,
  VideoInspectionMessageEnum,
  VideoInspectionMsgData,
  VideoInspectionMsgKey,
  VideoInspectionPostMessageData
} from "./videoinspection/utils";
import axios from "axios";
import {ReconnectContext} from "livekit-client/dist/src/room/ReconnectPolicy";

function App() {
  const encoder = new TextEncoder();
  const decoder = new TextDecoder();

  const videoInspectionInfo = useRef<VideoInspectionInfoData>({
    videoInspectionVersion: '1',
    videoInspectionNumber: Date.now().toString(),
    environment: "TEST"
  });
  const isClient = useRef(false);

  const serverUrl = VIDEOINSPECTION_CONFIG.serverUrl;
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token") || undefined;
  const lastVideoInspectionMessageTimestamp = React.useRef<number>();
  const connectTimestamp = React.useRef<number>();
  const dataReceivedTimestamp = React.useRef<number>();
  const stoppedAudio = React.useRef<boolean>(false);
  const mergeStateData = useRef<AudioMergeItem>({
    state: AUDIO_MERGE_STATE.MERGE_NOT_START,
    data: null
  });

  let mediaRecorder: any = null;
  let mediaRecorderRemote: any = null;

  const [room] = useState(new Room({
    audioCaptureDefaults: {
      autoGainControl: true,
      deviceId: '',
      echoCancellation: true,
      noiseSuppression: true,
    },
    videoCaptureDefaults: {facingMode: 'environment'},
    reconnectPolicy: {
      nextRetryDelayInMs(context: ReconnectContext): number | null {
        if (context.retryCount > 0) {
          return null;
        }
        return 500;
      }
    },
    expSignalLatency: 0,
    disconnectOnPageLeave: true
  }));

  // const [infoText, setInfoText] = useState<string>('Inicializacia hovoru ...');

  window.addEventListener("message", async (event) => {
    if (isVideoInspectionMessage(event)) {
      const msgData: VideoInspectionPostMessageData = event.data;
      if (lastVideoInspectionMessageTimestamp.current !== msgData.timestamp) {

        console.log(VideoInspectionMessageEnum.APP_NAME + '_window.addEventListener("message")', msgData.source, msgData.timestamp, msgData.data);

        lastVideoInspectionMessageTimestamp.current = msgData.timestamp;
        let sendMessage = true;

        if (msgData.data.key === VideoInspectionMsgKey.VIDEO_INSPECTION_INFO) {
          sendMessage = false;
          videoInspectionInfo.current = msgData.data.data;
        }
        if (isClient.current && msgData.data.key === VideoInspectionMsgKey.SENDING_IMAGE_ID) {
          await room.localParticipant.setCameraEnabled(true, {facingMode: 'environment'});
        }
        if (!isClient.current && msgData.data.key === VideoInspectionMsgKey.GET_AUDIO_DATA &&
          mediaRecorder && mediaRecorder.state !== 'inactive' && mediaRecorder.state === 'recording') {
          sendMessage = false;
          mediaRecorder.stop();
          if (mediaRecorderRemote && mediaRecorderRemote.state !== 'inactive' && mediaRecorderRemote.state === 'recording') {
            mediaRecorderRemote.stop();
          }
          sendLivekitMessage({key: VideoInspectionMsgKey.SERVER_DISCONNECTED}).then();
        }
        // else if (!isClient.current && msgData.data.key === VideoInspectionMsgKey.GET_AUDIO_DATA &&
        //   mediaRecorder && mediaRecorder.state === 'inactive') {
        //   sendPostMessage(VideoInspectionMsgKey.SENDING_BASE64_AUDIO_DATA, null);
        // }
        if (!isClient.current && msgData.data.key === VideoInspectionMsgKey.SERVER_DISCONNECTED) {
          disconnect().then();
        }
        if (!isClient.current && msgData.data.key === VideoInspectionMsgKey.CHECK_AUDIO_STATE) {
          sendPostMessage(VideoInspectionMsgKey.CHECK_AUDIO_STATE, mergeStateData.current);
        }

        if (sendMessage) {
          sendLivekitMessage({key: msgData.data.key, data: msgData.data.data, timestamp: msgData.timestamp}).then();
        }
      }
    }
  });

  window.addEventListener("error", (event) => {
    sendPostMessage(VideoInspectionMsgKey.LIVEKIT_ERROR, {type: event.type, message: event.message});
    console.log('window.addEventListener("error"):', event);
  });

  window.onerror = (errorEvent: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) => {
    const errorData = {
      errorEvent: getErrorString(errorEvent),
      source: source || '-',
      lineno: lineno || '-',
      colno: colno || '-',
      error: getErrorString(error) || null,
    }
    console.log('window.onerror:', errorData);
    sendPostMessage(VideoInspectionMsgKey.LIVEKIT_ERROR, errorData);
    return true;
  }

  room.on(RoomEvent.Connected, async () => {
    setupGui();
    connectTimestamp.current = Date.now();
    isClient.current = room.localParticipant.identity.includes('client');
    // setInfoText('');

    const item = document.querySelector('.lk-grid-layout-wrapper');
    if (item) {
      item.className += (room.localParticipant.identity.includes('client') ? ' client' : ' technician');
    }

    const remoteParticipant = getRemoteParticipant(room);
    if (remoteParticipant) {
      sendPostMessage(VideoInspectionMsgKey.CLIENT_CONNECTED, remoteParticipant.identity);
      //await room.localParticipant.setCameraEnabled(true, {facingMode: 'environment'});
    }
  });

  room.on(RoomEvent.Disconnected, async () => {
    const key = room.localParticipant.identity.includes('client') ? VideoInspectionMsgKey.CLIENT_DISCONNECTED : VideoInspectionMsgKey.TECHNICIAN_DISCONNECTED;
    sendPostMessage(key, room.localParticipant.identity);
  })

  room.on(RoomEvent.ParticipantConnected, async (participant: RemoteParticipant) => {
    participant.setVolume(1);
    participant.audioLevel = 1;
    const connectedClientParticipants = getClientParticipants();
    console.log('connectedClientParticipants', connectedClientParticipants);
    const key = participant.identity.includes('client') ? VideoInspectionMsgKey.CLIENT_CONNECTED : VideoInspectionMsgKey.TECHNICIAN_CONNECTED;
    if (key === VideoInspectionMsgKey.CLIENT_CONNECTED) {
        stoppedAudio.current = false;
    }
    sendPostMessage(key, participant.identity);
    sendLivekitMessage({key: key, data: participant.identity, timestamp: Date.now()}).then();
  });

  const getClientParticipants = (): RemoteParticipant[] => {
    if (room?.remoteParticipants) {
      const participants: RemoteParticipant[] = [];
      for (const [key, value] of room?.remoteParticipants) {
        if (value.identity.includes('client')) {
          participants.push(value);
        }
      }
      return participants;
    }
    return [];
  };

  room.on(RoomEvent.ParticipantDisconnected, async (participant: RemoteParticipant) => {
    if (!participant.identity.includes('client')) {
      sendPostMessage(VideoInspectionMsgKey.TECHNICIAN_DISCONNECTED, participant.identity);
    } else if (participant.identity.includes('client')) {
      sendPostMessage(VideoInspectionMsgKey.CLIENT_DISCONNECTED, participant.identity);
      if (mediaRecorder && mediaRecorder.state !== 'inactive' && mediaRecorder.state === 'recording') {
        mediaRecorder.stop();
      }
      if (mediaRecorderRemote && mediaRecorderRemote.state !== 'inactive' && mediaRecorderRemote.state === 'recording') {
        mediaRecorderRemote.stop();
      }
    }
  });

  room.on(RoomEvent.ConnectionStateChanged, () => {
    sendPostMessage(VideoInspectionMsgKey.CONNECTION_STATE, room.state);
  });

  room.on(RoomEvent.DataReceived, async (payload: Uint8Array, participant?: RemoteParticipant) => {
    const incomingData = decoder.decode(payload);
    const incomingDataObj: VideoInspectionMsgData = JSON.parse(incomingData);

    console.log('room.on(RoomEvent.DataReceived) isClient.current:', isClient.current, incomingDataObj.key, incomingDataObj.timestamp, incomingDataObj.data);

    if (incomingDataObj.timestamp && connectTimestamp.current && incomingDataObj.timestamp < connectTimestamp.current
        && (dataReceivedTimestamp.current && dataReceivedTimestamp.current !== incomingDataObj.timestamp)) {
      return;
    }
    dataReceivedTimestamp.current = Date.now();

    if (isClient.current && incomingDataObj.key.toString().includes('CAPTURE_')) {
      await room.localParticipant.setCameraEnabled(false);
    }
    if (isClient.current && incomingDataObj.key === VideoInspectionMsgKey.SERVER_DISCONNECTED) {
      disconnect().then(() => {
        sendPostMessageData(incomingDataObj);
      });
    } else {
      sendPostMessageData(incomingDataObj);
    }
  });

  room.on(RoomEvent.TrackSubscribed, (track: RemoteTrack, publication: RemoteTrackPublication,
                                      participant: RemoteParticipant) => {
    if (participant.identity.includes('client') && track && track.kind === 'audio' && track.mediaStream) {
      try {
        recordAudioTracks(track.mediaStream);
      } catch (error) {
        sendPostMessage(VideoInspectionMsgKey.LIVEKIT_ERROR, getErrorString(error));
      }
    }
  });

  room.on(RoomEvent.ActiveDeviceChanged, (kind: MediaDeviceKind, deviceId: string) => {
    console.log('!!! RoomEvent.ActiveDeviceChanged', kind, deviceId);
    if (mediaRecorderRemote && mediaRecorderRemote.state !== 'inactive' && mediaRecorderRemote.state === 'recording') {
        mediaRecorderRemote.stop();
    }
    if (!isClient.current && mediaRecorder && mediaRecorder.state === 'inactive') {
      const remoteParticipant = getRemoteParticipant(room);
      if (remoteParticipant && remoteParticipant?.audioTrackPublications
          && (remoteParticipant?.audioTrackPublications?.entries()?.next()?.value as any[]).length > 0) {
        try {
          recordAudioTracks((remoteParticipant?.audioTrackPublications?.entries()?.next()?.value as any[])[1]!.track!.mediaStream);
        } catch (error: any) {
          sendPostMessage(VideoInspectionMsgKey.LIVEKIT_ERROR, getErrorString(error));
        }
      }
    }
  });

  const sendLivekitMessage = async (data: VideoInspectionMsgData) => {
    const remoteParticipant = getRemoteParticipant(room);
    if (remoteParticipant) {
      // max 65536 bytes
      await room.localParticipant.publishData(encoder.encode(JSON.stringify(data)),
        {reliable: true, destinationIdentities: [remoteParticipant.identity]});
    }
  };

  const sendPostMessage = (key: VideoInspectionMsgKey, data: any | VideoInspectionGeolocation) => {
    sendPostMessageData({key, data});
  };

  const getRemoteParticipant = (room: Room): RemoteParticipant | null => {
    let remoteParticipant = null;
    if (room?.remoteParticipants.size === 1) {
      room?.remoteParticipants.forEach((value: RemoteParticipant, key: string) => {
        remoteParticipant = value;
      });
    }
    return remoteParticipant;
  };

  const recordAudioTracks = (remoteMediaStream: MediaStream) => {
    const localAudioMediaStream = new MediaStream();

    const localAudioTracksKey = room.localParticipant.audioTrackPublications.keys().next().value as string;
    const localAudioTrack = room.localParticipant.audioTrackPublications.get(localAudioTracksKey);
    if (!localAudioTrack || !localAudioTrack.track || !localAudioTrack.track.mediaStream || !remoteMediaStream) {
      console.error('!! empty MediaStream', localAudioTrack, remoteMediaStream);
      sendPostMessage(VideoInspectionMsgKey.SENDING_BASE64_AUDIO_DATA, null);
      return;
    }
    localAudioMediaStream.addTrack(localAudioTrack.track.mediaStream.getAudioTracks()[0]);

    let mimeType = '';
    if (MediaRecorder.isTypeSupported('audio/mpeg')) {
      mimeType = 'audio/mpeg';
    } else if (MediaRecorder.isTypeSupported('audio/webm')) {
      mimeType = 'audio/webm';
    } else if (MediaRecorder.isTypeSupported('audio/ogg')) {
      mimeType = 'audio/ogg';
    }

    const options = {
      audioBitsPerSecond: 96000,
      mimeType: mimeType
    };
    mediaRecorder = new MediaRecorder(localAudioMediaStream, options);
    mediaRecorderRemote = new MediaRecorder(remoteMediaStream, options);

    let recordedChunks: any[] = [];
    let recordedChunksRemote: any[] = [];
    mediaRecorder.addEventListener('dataavailable', (e: any) => {
      if (e.data.size > 0) {
        recordedChunks.push(e.data);
      }
    });

    mediaRecorderRemote.addEventListener('dataavailable', (e: any) => {
      if (e.data.size > 0) {
        recordedChunksRemote.push(e.data);
      }
    });

    let base64Array: string[] = [];
    mediaRecorder.addEventListener('stop', () => {
      if (!stoppedAudio.current) {
        sendPostMessage(VideoInspectionMsgKey.SAVING_AUDIO_DATA, 'mediaRecorder.addEventListener("stop")');
        stoppedAudio.current = true;
      }
      const webmBlob = new Blob(recordedChunks, {type: mimeType});
      if (webmBlob.size) {
        blobToBase64(webmBlob).then((base64: string) => {
          if (base64) {
            base64 = base64.indexOf(',') > -1 ? base64.split(',')[1] : base64;
            base64Array.push(base64);
            if (base64Array.length > 1) {
              getMergedAudio(base64Array, true).then((data: string | null) => {
                mergeStateData.current.state = AUDIO_MERGE_STATE.MERGE_DONE;
                if (data) {
                  sendPostMessage(VideoInspectionMsgKey.SENDING_BASE64_AUDIO_DATA, data);
                  // mergeStateData.current.data = {tms: Date.now(), mergeData: data};
                } else {
                  const finalBase = base64Array[0] + '_audio1_' + base64Array[1];
                  sendPostMessage(VideoInspectionMsgKey.SENDING_BASE64_AUDIO_DATA, finalBase);
                  // mergeStateData.current.data = {tms: Date.now(), mergeData: finalBase};
                }
              });
            }
          }
        });
      }
    });

    mediaRecorderRemote.addEventListener('stop', () => {
      if (!stoppedAudio.current) {
        sendPostMessage(VideoInspectionMsgKey.SAVING_AUDIO_DATA, 'mediaRecorderRemote.addEventListener("stop")');
        stoppedAudio.current = true;
      }
      const webmBlob = new Blob(recordedChunksRemote, {type: mimeType});
      if (webmBlob.size) {
        blobToBase64(webmBlob).then((base64: string) => {
          if (base64) {
            base64 = base64.indexOf(',') > -1 ? base64.split(',')[1] : base64;
            base64Array.push(base64);
            if (base64Array.length > 1) {
              getMergedAudio(base64Array, false).then((data: string | null) => {
                mergeStateData.current.state = AUDIO_MERGE_STATE.MERGE_DONE;
                if (data) {
                  sendPostMessage(VideoInspectionMsgKey.SENDING_BASE64_AUDIO_DATA, data);
                  // mergeStateData.current.data = {tms: Date.now(), mergeData: data};
                } else {
                  const finalBase = base64Array[0] + '_audio2_' + base64Array[1];
                  // mergeStateData.current.data = {tms: Date.now(), mergeData: finalBase};
                }
              });
            }
          }
        });
      }
    });

    mediaRecorder.addEventListener("error", (event: any) => {
      console.error('error recording mediaRecorder stream', event);
      mergeStateData.current.state = AUDIO_MERGE_STATE.MERGE_ERROR;
      mergeStateData.current.data = {tms: Date.now(), mergeData: getErrorString(event)};
      sendPostMessage(VideoInspectionMsgKey.CHECK_AUDIO_STATE, mergeStateData.current);
    });

    mediaRecorderRemote.addEventListener("error", (event: any) => {
      console.error('error recording mediaRecorderRemote stream', event);
      mergeStateData.current.state = AUDIO_MERGE_STATE.MERGE_ERROR;
      mergeStateData.current.data = {tms: Date.now(), mergeData: getErrorString(event)};
      sendPostMessage(VideoInspectionMsgKey.CHECK_AUDIO_STATE, mergeStateData.current);
    });

    setTimeout(() => {
      mediaRecorder.start();
      mediaRecorderRemote.start();
      mergeStateData.current.state = AUDIO_MERGE_STATE.MERGE_START;
      mergeStateData.current.data = Date.now();
      sendPostMessage(VideoInspectionMsgKey.CLIENT_START_AUDIO, true);
      sendPostMessage(VideoInspectionMsgKey.CHECK_AUDIO_STATE, mergeStateData.current);
    }, 200);
  };

  const getMergedAudio = (base64Array: string[], remoteFirst: boolean): Promise<string | null> => {
    return new Promise((response) => {
      const request = getMergedAudioRequest(base64Array, remoteFirst, videoInspectionInfo.current.videoInspectionVersion);

      axios({
        method: 'post',
        url: VIDEOINSPECTION_CONFIG.ocrUrl[videoInspectionInfo.current.environment] + '/v3/mergeAndDownload',
        data: request,
        headers: {
          'Access-Control-Allow-Origin': '*',
        }
      }).then((responseData: any) => {
        if (responseData && responseData.data) {
          if (!responseData.data.startsWith('data:audio')) {
            responseData.data = 'data:audio/mp3;base64,' + responseData.data;
          }
          response(responseData.data);
        } else {
          response(null);
        }
      }).catch((error: any) => {
        console.error('getMergedAudio', error);
      });
    });
  }

  const disconnect = (): Promise<void> => {
    return new Promise((response) => {
      if (room) {
        setTimeout(async () => {
          room.disconnect(true).then(() => {
            response();
          })
        }, 250);
      }
    });
  }

  const onError = (error: any) => {
    console.error(error);
    sendPostMessage(VideoInspectionMsgKey.LIVEKIT_ERROR, getErrorString(error));
  }

  return (
    <>
      {/*{infoText ? (*/}
      {/*  <div className='info-text'>{infoText}</div>*/}
      {/*) : (*/}
        <LiveKitRoom
          room={room}
          data-lk-theme="default"
          token={token}
          serverUrl={serverUrl}
          connect={true}
          video={true}
          audio={true}
          onError={onError}
          // Use the default LiveKit theme for nice styles.
          style={{ height: '100vh' }}
        >
          <VideoConference>
          </VideoConference>
        </LiveKitRoom>
      {/*)}*/}
    </>
  );
}

export default App;



