export const VIDEOINSPECTION_CONFIG = {
  serverUrl: 'wss://livekit-server.obhliadka.emeldi.com',
  mlpuObhliadkaUrl: {
    TEST: 'https://b2bportaltest.csobpoistovna.sk/api/mlpu-obhliadka',
    PROD: 'https://b2bportal.csobpoistovna.sk/api/mlpu-obhliadka',
    ACC: 'https://b2bportalacc.csobpoistovna.sk/api/mlpu-obhliadka',
  },
  ocrUrl: {
    TEST: 'https://b2bportaltest.csobpoistovna.sk/api/ocr',
    PROD: 'https://b2bportal.csobpoistovna.sk/api/ocr',
    ACC: 'https://b2bportalacc.csobpoistovna.sk/api/ocr',
  }
};
