import dayjs from "dayjs";

declare const window: any;

export enum VideoInspectionMsgKey {
  CLIENT_CONNECTED = 'CLIENT_CONNECTED',
  CLIENT_DISCONNECTED = 'CLIENT_DISCONNECTED',
  VIDEO_INSPECTION_INFO = 'VIDEO_INSPECTION_INFO',
  SENDING_IMAGE_ID = 'SENDING_IMAGE_ID',
  SERVER_DISCONNECTED = 'SERVER_DISCONNECTED',
  TECHNICIAN_DISCONNECTED = 'TECHNICIAN_DISCONNECTED',
  TECHNICIAN_CONNECTED = 'TECHNICIAN_CONNECTED',
  RECONNECTED = 'RECONNECTED',
  SENDING_BASE64_AUDIO_DATA = 'SENDING_BASE64_AUDIO_DATA',
  GET_AUDIO_DATA = 'GET_AUDIO_DATA',
  CLIENT_START_AUDIO = 'CLIENT_START_AUDIO',
  CONNECTION_STATE = 'CONNECTION_STATE',
  SAVING_AUDIO_DATA = 'SAVING_AUDIO_DATA',
  LIVEKIT_ERROR = 'LIVEKIT_ERROR',
  CHECK_AUDIO_STATE = 'CHECK_AUDIO_STATE',
}

export enum AUDIO_MERGE_STATE {
  MERGE_NOT_START = 'MERGE_NOT_START',
  MERGE_START = 'MERGE_START',
  MERGE_DONE = 'MERGE_DONE',
  MERGE_ERROR = 'MERGE_ERROR',
}

export interface AudioMergeItem {
  state: AUDIO_MERGE_STATE,
  data: any
}

export interface VideoInspectionGeolocation {
  latitude?: string | number;
  longitude?: string | number;
  message?: string;
}

export interface VideoInspectionInfoData {
  environment: 'TEST' | 'PROD' | 'ACC',
  videoInspectionVersion: string,
  videoInspectionNumber: string,
}

export interface VideoInspectionMsgData {
  key: VideoInspectionMsgKey;
  data?: any | VideoInspectionInfoData | VideoInspectionGeolocation;
  timestamp?: number;
}

export enum VideoInspectionMessageEnum {
  APP_NAME = 'LIVEKIT_APP',
  VIDEOINSPECTION_APP = 'VIDEOINSPECTION_APP',
  CLIENT_APP = 'CLIENT_APP',
}

export interface VideoInspectionPostMessageData {
  source: VideoInspectionMessageEnum.APP_NAME,
  data: VideoInspectionMsgData;
  timestamp: number;
}

export interface MergeMp3Request {
  firstSound?: string;
  firstSoundName?: string;
  outputName?: string;
  secondSound?: string;
  secondSoundName?: string;
}

export const isVideoInspectionMessage = (event: any): boolean => {
  return event?.data && typeof event.data === 'object' && (event.data?.source === VideoInspectionMessageEnum.VIDEOINSPECTION_APP
      || event.data?.source === VideoInspectionMessageEnum.CLIENT_APP);
};

export const sendPostMessageData = (messageData: VideoInspectionMsgData) => {

  const message: VideoInspectionPostMessageData = {
    source: VideoInspectionMessageEnum.APP_NAME,
    data: messageData,
    timestamp: Date.now()
  };
  console.log('sendPostMessageData', message.source, message.data, message.timestamp);

  if (!!(window as any).ReactNativeWebView) {
    (window as any).ReactNativeWebView.postMessage(message, '*');
  } else {
    window.postMessage(message, '*');
    if (window.parent) {
      window.parent.postMessage(message, '*');
    }
  }
};

export const setWaitingText = (text?: string) => {
  const gridItemLayout = document.querySelector('.lk-grid-layout');
  const gridItemLayoutIemEmpty = document.querySelector('.lk-grid-item-empty');
  if (gridItemLayout && !gridItemLayoutIemEmpty) {
    const newDiv = document.createElement("div");
    newDiv.innerText = text ? text : '...';
    newDiv.className = 'lk-grid-item-empty';
    gridItemLayout.appendChild(newDiv);
  } else if (gridItemLayoutIemEmpty) {
    (gridItemLayoutIemEmpty as any).innerText = text;
  }
};

export const downloadBlob = (blob: any, fileName: string) => {
  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
    (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
  } else {
    const linkElem = document.createElement('a') as any;
    linkElem.href = window.URL.createObjectURL(blob);
    linkElem.download = fileName;
    linkElem.target = '_blank';
    linkElem.click();
  }
};

export const blobToBase64 = (blob: any): Promise<string> => {
  return new Promise<string>((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as any);
    reader.readAsDataURL(blob);
  });
};

export const createFileName = (prefix: string): string => {
  return prefix + dayjs(new Date()).format('ddMMyyyyHHmmss');
}

export const getMergedAudioRequest = (base64Array: string[], remoteFirst: boolean, videoInspectionVersion: string): MergeMp3Request => {
  const fileName: string = createFileName('audio_v' + videoInspectionVersion + '_') + '.wav';
  const fileNameClient: string = createFileName('audio_v' + videoInspectionVersion + '_client_') + '.wav';
  const outputName: string = createFileName('audio_v' + videoInspectionVersion + '_merge_') + '.mp3';

  return {
    firstSound: remoteFirst ? base64Array[1]  : base64Array[0],
    firstSoundName: fileName,
    secondSound:  remoteFirst ? base64Array[0]  : base64Array[1],
    secondSoundName: fileNameClient,
    outputName: outputName
  };
}

export const checkMediaDevice = (mediaType: 'audio' | 'video'): Promise<boolean> => {
  return new Promise((response) => {
    const audio = mediaType === 'audio';
    const video = mediaType === 'video';
    navigator.mediaDevices.getUserMedia({audio, video})
      .then((stream) => {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
        response(true);
      })
      .catch((error) => {
        console.error('checkMediaDevice', error.message);
        response(false);
      });
  });
}

export const setupGui = () => {
  const controlBarEls = document.getElementsByClassName('lk-control-bar');
  if (controlBarEls) {
    const controlBar = controlBarEls[0] as HTMLElement;
    const buttons = controlBar.getElementsByClassName('lk-button-group') || [];
    for (let button of buttons) {
      if (button.querySelector('button[data-lk-source="microphone"]')) {
        (button as HTMLElement).className += ' visible';

        const lkDeviceMenu = button.querySelector('.lk-device-menu') as HTMLElement;
        if (lkDeviceMenu) {
          const lkMediaDeviceSelect = lkDeviceMenu.querySelector('.lk-media-device-select') as HTMLElement;
          if (lkMediaDeviceSelect) {
            let width = lkMediaDeviceSelect.offsetWidth;
            let height = lkMediaDeviceSelect.offsetHeight;
            lkDeviceMenu.style.left =(((width / 2) * -1) - 65)  + 'px';
            lkDeviceMenu.style.top = ((height + 25) * -1) + 'px';
          }
        }
      }
    }
  }
};

export const getErrorString = (error: any): string => {
  try {
    let errorStr = '';
    if (error && error?.stack) {
      errorStr = error?.stack;
    } else if (error && error?.message) {
      errorStr = error?.message;
    } else if (error) {
      errorStr = error.toString();
    }
    return errorStr;
  } catch (e) {
    console.error(e);
    return '';
  }
}
