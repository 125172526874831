import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// todo
if (!(window as any).process || !process) {
  (window as any).process = {
    ...(window as any).process,
  };
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const SK: { [key: string]: string }= {
  '': '',
  'Default': 'Predvolený',
  'Speakerphone': 'Hlasitý odposluch',
  'Headset earpiece': 'Slúchadlá',
}

const checkDevices = () => {
  let deviceListSelects: any = document.getElementsByClassName('lk-media-device-select');
  let menuIndex = 0;
  for (const deviceListSelect of deviceListSelects) {
    const lis = deviceListSelect.getElementsByTagName('li');
    let deviceIndex = 1;
    for (const li of lis) {
      const button = li.getElementsByTagName('button');
      if (li.id === 'default' && !button[0].textContent) {
        button[0].textContent = 'Default';
      } else if (!button[0].textContent && menuIndex === 0) {
        button[0].textContent = 'Microphone ' + deviceIndex;
      } else if (!button[0].textContent && menuIndex === 1) {
        button[0].textContent = 'Camera ' + deviceIndex;
      }
      const strText: string = button[0].textContent || '';
      if (SK[strText]) {
        button[0].textContent = SK[strText];
      }
      deviceIndex++;
    }
    menuIndex++;
  }
};

const checkDeviceNames = () => {
  let deviceListSelects: any = document.getElementsByClassName('lk-media-device-select');
  for (const deviceListSelect of deviceListSelects) {
    const lis = deviceListSelect.getElementsByTagName('li');
    for (const li of lis) {
      const button = li.getElementsByTagName('button');
      if (!button[0].textContent) {
        return false;
      }
    }
  }
  return true;
};

var interval = setInterval(() => {
  if (checkDeviceNames()) {
    clearInterval(interval);
  } else {
    checkDevices();
  }
}, 2000);
